import React from "react";

import Scaffold from "../../../components/portfolio";
import movies from "../../../images/movies.png";

const Design = (props) => (
  <Scaffold pathname={props.location.pathname}>
    <h2>Movie Analytics</h2>

    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      <div>
        <a href="http://movies.davidcook.io/" target="_blank">
          <div className="browser-mockup">
            <img style={{ maxWidth: "100%", height: 180 }} src={movies} />
          </div>
        </a>
      </div>
    </div>

    <p>
      I like watching movies. Over several years I recorded and rated movies I
      watched, and wrote a web app experiment that can analyse, visualise and
      explore that data, along with a simple linear regression model to predict
      how much I would enjoy made up movies.
    </p>

    <p>
      The website is build using Gatsby and React, and uses Recharts for the
      graphs on the website. I used{" "}
      <a href="https://www.themoviedb.org" target="_blank">
        The Movie DB
      </a>{" "}
      API to download movie posters, genre information, and other film
      attributes including budget and parental guidance information.
    </p>

    <a href="http://movies.davidcook.io/" target="_blank">
      <button
        style={{
          borderRadius: 4,
          padding: 10,
          paddingLeft: 15,
          paddingRight: 15,
          cursor: "pointer",
        }}
      >
        See the App
      </button>
    </a>

    <br />
    <br />
  </Scaffold>
);

export default Design;
